.react-calendar {
    width: 100%;
    max-width: 100%;
    background-color: #fff;
    color: #222;
    border-radius: 8px;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
    margin-top: 15px;
    border: 0px;
    pointer-events: none;
}

.react-calendar__month-view__days button {
    margin-bottom: 10px;
    padding: 0px;
}

.react-calendar__month-view__days button .day-number {
    font-weight: bold;
}

.react-calendar__month-view__days button .unit-total {
    margin-top: 5px;
}

.react-calendar__tile--now {
    font-weight: normal !important;
}

@media all and (max-width: 480px) {
    button.react-calendar__tile span {
        font-size: 12px !important;
    }
    .react-calendar__month-view__days button .unit-total {
        margin-top: 0px;
    }
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background: #f8f8fa;
    color: #007bff;
}
.react-calendar__tile--now {
    background: #007bff;
    border-radius: 6px;
    font-weight: bold;
    color: #fff;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    background: #6f48eb33;
    border-radius: 6px;
    font-weight: bold;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    background: #f8f8fa;
}
.react-calendar__tile--active {
    background: #007bff;
    border-radius: 6px;
    font-weight: bold;
    color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: #007bff;
    color: white;
}
